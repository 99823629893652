export default defineNuxtRouteMiddleware(async (to, from) => {
  const host = window.location.host;
  const subdomain = host.split(".")[0];

  if (subdomain === "app") {
    return navigateTo("/switch");
  } else {
    return;
  }
});
